/* Button */
.btn-holder {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.btn-holder .tag-btn {
    border-bottom: 0;
    display: inline-block;
    margin: 5px 3px;
    padding: 5px 10px;
    width: auto;
    background: #e8e8e8;
    border-radius: 3px;
    font-weight: 400;
}

/* Two Columns Section */
.two-columns-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.two-columns-holder img {
    max-height: 485px;
}

/*  */
.use-cases {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.free-forever {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.free-forever-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* CTA Section */
.cta-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.pr40 {
    padding-right: 40px;
}
.cta-text ul {
    display: flex;
    gap: 20px;
}
.cta-text p {
    display: flex;
}
.cta-text p i {
    margin-right: 10px;
}

/* Product Page */

.product-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.link-color {
    color: var(--main-theme-color);
}

/* Planning Page */
.support-features-p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.support-features-headline {
    font-size: 28px;
    color: #3D4853;
    display: inline-block;
}
.support-features-explainer {
    display: block;
    font-size: 14px;
    color: #78909C;
    line-height: 1.3;
}
.customer-logos {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}
.customer-logos .row {
    justify-content: center;
}

/* Responsive */
@media (max-width: 767px) {
    .cta-text ul {
        gap: 10px;
        flex-direction: column;
    }
    .separate {
        display: none;
    }
}
@media (max-width: 576px) {
    .pr40 {
        padding-right: 0px;
    }
}



/* Mega Menu */

.submenu.sitewidth {
    display: flex;
    max-width: 1120px;
    margin: -15px auto 0;
    gap: 1rem;
    justify-content: space-between;
    padding-bottom: 2.5rem;
    padding-top: 2rem;
 }
.submenu .menu__column.plans {
    padding-left: 40px;
    padding-bottom: 40px;
}
.submenu .menu__column.after-parent {
    position: relative;
}
.submenu .menu__column .column-heading {
    border-bottom: 1px solid #E8EDF0;
}
.submenu .menu__column .column-heading h5 {
    font-size: 0.75rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    line-height: 120%;
    color: #7F9CAD;
    margin-bottom: 0.5rem;
}
.submenu .menu__items-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.submenu .menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 10px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 calc(50% - 24px);
    flex: 1 1 calc(50% - 24px);
    padding: 10px 5px !important;
    margin: 0 !important;
}
.navbar .navbar__menu a, .navbar .navbar__buttons a {
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
.submenu .menu__item .item__icon {
    min-width: 24px;
    max-width: 24px;
}
.submenu .menu__item .item__icon img {
    width: 100%;
    transition: all 0.3s ease-in-out;
}
.submenu .menu__item:hover .item__icon img {
    filter: brightness(0) saturate(100%) invert(40%) sepia(93%) saturate(2250%) hue-rotate(217deg) brightness(94%) contrast(91%);
}
.submenu .menu__item .item__title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 168%;
    color: #3D4853;
    transition: all 0.3s ease-in-out;
}
.submenu .menu__item:hover .item__title {
    color: var(--main-theme-color);
}
.submenu .menu__item .item__desc {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 140%;
    color: #78909C;
}
.submenu .menu__column.plans .menu__items-wrapper {
    row-gap: 20px;
}
.submenu .menu__column.plans .menu__item {
    border: 1px solid #E8EDF0;
    border-radius: 6px;
    padding: 10px;
}
.plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu {
    width: 100%;
}
.bottom-banner {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 11;
    background-color: #718191;
    padding: 1rem;
}
.bottom-banner .sitewidth {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1186px;
    margin: 0 auto;
}
.bottom-banner .sitewidth > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 40px;
}
.bottom-banner a {
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 6px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
    font-weight: bold;
}
.plamb-nav .navbar .navbar-nav .nav-item a.white {
    color: #fff;
}
.features-mega-menu.submenu .menu__item {
    flex-basis: calc(100% - 0px);
}
@media screen and (min-width: 1160px) {
    .submenu .menu__column.mw-455 {
      max-width: 455px;
    }
	.submenu .menu__column.mw-233 {
      max-width: 273px;
    }
	.submenu .menu__column.mw-328 {
      max-width: 328px;
    }
}
@media screen and (min-width: 992px) {
    .plamb-nav .navbar .navbar-nav .nav-item {
        position: initial;
    }
    .mobile-link {
        display: none;
    }
}
@media (max-width: 991px) {
    .navbar-area {
        padding: 0px 0px;
    }
    .navbar-area .container {
        max-width: 990px;
    }
    .plamb-nav .navbar .navbar-nav {
        padding: 45px 15px;
        overflow-y: scroll;
        height: 85vh;
    }
    .plamb-nav .navbar .navbar-nav .nav-item a {
        margin-left: 0;
        margin-right: 0;
    }
    .responsive-button {
        right: 20px;
        cursor: pointer;
    }
    /*  */
    .submenu.sitewidth {
        flex-direction: column;
    }
    .menu__items-wrapper {
        flex-direction: column;
    }
    .plamb-nav .navbar .navbar-nav .nav-item a {
        justify-content: flex-start;
    }
    .navbar-area.is-sticky {
        position: sticky;
    }
    .submenu .menu__column.plans {
        padding-left: 0;
        padding-bottom: 0;
    }
    .bottom-banner .sitewidth > div {
        flex-direction: column;
        gap: 25px;
    }
    .bottom-banner {
        position: relative;
    }
    .submenu.sitewidth {
        gap: 1rem;
        padding-bottom: 2rem;
    }
    .bottom-banner .sitewidth .rating-reviews {
        display: none; 
    }
    .plamb-nav .navbar .navbar-nav .nav-item {
        border-bottom: 1px solid #E8EDF0;
        display: flex;
        flex-direction: column;
    }
    .plamb-nav .navbar .navbar-nav .nav-item.has-children > a {
        width: 50%;
    }
    .plamb-nav .navbar .navbar-nav .nav-item.has-children > a i {
        display: none;
    }
    .plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu {
        position: relative;
        box-shadow: none;
        top: 0;
        opacity: 1;
        visibility: visible;
        padding-top: 25px;
        padding-left: 0;
        padding-right: 0px;
        display: none;
    }
    .plamb-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
        top: 0;
    }
    .plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu.dropdown-menu-product.sub-menu-show, 
    .plamb-nav .navbar .navbar-nav .nav-item .dropdown-menu.dropdown-menu-features.sub-menu-show {
        display: block;
    }
    .mobile-link {
        position: absolute;
        right: 0;
        width: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .mean-menu {
        left: -16px;
        width: calc(100% + 31px);
    }
}

/* Banner */
#banner-five {
    min-height: 530px;
}
.pricing-table {
    z-index: 9;
}
#one-testimonial .owl-nav .owl-prev, #one-testimonial .owl-nav .owl-next {
    font-size: 45px;
}
#banner-five.mb200 {
    margin-bottom: 200px;
}
@media (max-width: 768px) {
    .home-one-banner-content  h1 {
        font-size: 50px;
    }
}

/* Map */
.google-map-code {
    width: 100%;
    display: flex;
    height: 50vh;
}

/* Footer */
@media (max-width: 767px) {
	.responsive-pl0 {
  		padding-left: 0;
	}
}