.custom-list li {
  list-style: disc !important;
  color: #777c87;
}

.margin-custom {
  margin-left: 38px;
}
.heading-section-color {
  color: var(--main-theme-color);
}
.paragraph-color{
  color: var(--main-theme-color);
}
.paragraph-color-a a{
  color: var(--main-theme-color);
  
}
.paragraph-color-a a:hover{
  text-decoration: underline;
  
}

.span-color {
  color: var(--main-theme-color);
}

.span-color:hover {
  text-decoration: underline;
}
.hover-color:hover {
  color: var(--main-theme-color);
}

.background-color-middle {
  background-color: var(--dark-bg-color);
}
.step-font {
  font-size: 12px;
}
.step-heading {
  font-size: 16px;
  margin: 8px 0px;
}
.step-note {
  font-size: 14px;
}
.section-padding {
  padding: 40px 0px;
}

.icon-large {
  font-size: 24px;
}
.feature-icon {
  font-size: 24px;
  color: var(--main-theme-color);
  transition: all 0.5s ease-in; /* Apply transition to all properties */
}

.feature-icon:hover {
  transform: rotateY(360deg); /* Apply transform only on hover */
}
.iocncolor{
  color: var(--main-theme-color);
}
.fsSize {
  font-size: 34px;
}
.rating-color {
  color: #777c87;
}

.pyimage {
  padding: 45px 0px;
}
.margintop {
  margin-top: 18px;
}

@media (max-width: 767px) {
  .styleclass th {
    writing-mode: vertical-lr; /* Display text vertically */
    transform: rotate(-180deg); /* Rotate text to display properly */
    width: 20px; /* Adjust width to fit vertically */
  }
}
.m40 {
  margin: 40px;
}

.label-purple {
  background-color: #e6f6fe;
  color: #03a9f4;
  padding: 8px;
  border-radius: 5px;
}

.iconstyle {
  left: 167px;
  position: absolute;
  top: 3px;
}
.heading-color-bg {
  background-color: #ebf0ff;
}
.fw600{
  font-weight: 500;
}
.iconstyle-arrow{
  /* left: 180px; */
  position: absolute;
  top: 3px;
}
.iconstyle-arrow-first{
  /* left: 110px; */
  position: absolute;
  top: 3px;
}
.related-wrapper {
  border-left: 5px solid #40a9f4;
}
.marginleft-list li{
  margin-left: 24px;
}


.image-zoom {
  overflow: hidden;
  display: inline-block;
}

.image-zoom img {
  transition: transform 0.3s ease;
}

.image-zoom:hover img {
  transform: scale(1.2);
}
